import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from "../context/AuthContext";

const Login = () => {
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState(null);
  const [error, setError] = useState(null);
  const [polling, setPolling] = useState(false);
  const [loginAttempt, setLoginAttempt] = useState(false);

  const sessionId = new URLSearchParams(window.location.search).get('session_id');

  useEffect(() => {
    let intervalId = null;

    const pollCredentials = async () => {
      try {
        console.log("Polling for credentials...");
        const response = await fetch(`https://gothos.biz/api/get_temp_credentials?session_id=${sessionId}`);

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log("Received data:", data);

        if (data.status === 'complete') {
          setPolling(false);
          setCredentials(data);
          if (data.existing_user) {
            setError('Please login with your existing credentials');
          } else {
            handleLogin(data);
          }
          if (intervalId) {
            clearInterval(intervalId);
          }
        } else if (data.error) {
          setError(data.error);
          setPolling(false);
          if (intervalId) {
            clearInterval(intervalId);
          }
        }
      } catch (error) {
        console.error('Error fetching credentials:', error);
        setError(error.message);
        setPolling(false);
        if (intervalId) {
          clearInterval(intervalId);
        }
      }
    };

    if (sessionId && !credentials?.status) {
      setPolling(true);
      pollCredentials();
      intervalId = setInterval(pollCredentials, 2000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [sessionId, credentials?.status]);

  const handleLogin = async (creds) => {
    if (loginAttempt) return;
    setLoginAttempt(true);

    try {
      // If it's an existing user without temp password
      if (creds.existing_user) {
        setError('Please login with your existing credentials');
        setLoginAttempt(false);
        return;
      }

      const loginData = {
        username: creds.username,
        password: creds.temp_password,
        wallet_address: creds.wallet_address
      };

      console.log("Attempting login with data:", {
        ...loginData,
        password: loginData.password ? '[PRESENT]' : '[MISSING]'
      });

      const success = await login(loginData);

      if (success) {
        navigate('/dashboard');
      } else {
        setError('Login failed. Please try again.');
      }
    } catch (error) {
      console.error('Login error:', error);
      setError('Login failed. Please try again.');
    } finally {
      setLoginAttempt(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center p-4">
      <div className="max-w-md w-full bg-white rounded-lg shadow-lg p-6">
        {error && (
          <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4">
            {error}
          </div>
        )}

        {polling && !credentials && (
          <div className="text-center">
            <h2 className="text-2xl font-bold mb-4">Processing Payment</h2>
            <p className="text-gray-600">Please wait while we process your payment...</p>
          </div>
        )}

        {credentials && credentials.status === 'complete' && !credentials.existing_user && (
          <div>
            <h2 className="text-2xl font-bold mb-4">Your Login Credentials</h2>
            <div className="space-y-4 mb-4">
              <div>
                <p className="text-sm text-gray-600">Username:</p>
                <p className="font-medium">{credentials.username}</p>
              </div>
              <div>
                <p className="text-sm text-gray-600">Wallet Address:</p>
                <p className="font-mono text-sm">{credentials.wallet_address}</p>
              </div>
              {credentials.temp_password && (
                <div>
                  <p className="text-sm text-gray-600">Temporary Password:</p>
                  <p className="font-mono">{credentials.temp_password}</p>
                </div>
              )}
            </div>
            <button
              onClick={() => handleLogin(credentials)}
              className="w-full bg-purple-600 text-white p-2 rounded mt-4 hover:bg-purple-700"
              disabled={loginAttempt}
            >
              {loginAttempt ? 'Logging in...' : 'Login'}
            </button>
          </div>
        )}

        {!polling && !credentials && !error && (
          <div className="text-center">
            <p className="text-gray-600">No active session found.</p>
          </div>
        )}

        {credentials && credentials.existing_user && (
          <div className="text-center">
            <h2 className="text-xl font-bold mb-4">Welcome Back!</h2>
            <p className="text-gray-600 mb-4">
              Your balance has been updated. Please log in with your existing credentials.
            </p>
            <button
              onClick={() => navigate('/login')}
              className="bg-blue-600 text-white px-6 py-2 rounded hover:bg-blue-700"
            >
              Go to Login
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;